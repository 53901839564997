<template>
  <div>
    <section  class="container personalize" v-if="!loading">
      <!-- <h2>Personalize sua casa agora</h2> -->
      <!-- <div class="casas"></div> -->
    </section>
    <!-- <section class="container personalize" v-if="loading">
      <h2>
        <b-skeleton animation="wave" width="30%"></b-skeleton>
      </h2>
      <div class="casas">
        <b-skeleton animation="wave" width="20%" type="button"></b-skeleton>
      </div>
    </section> -->
  </div>
</template>

<script>
export default {
  name: "Personalize",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

    personalize_img: {
      type: String,
      default: "",
    },
  },
  mounted() {
    setTimeout(() => {
      document.querySelector(
        ".casas"
      ).style.backgroundImage = `url(${this.personalize_img})`;
    }, 100);
  },
};
</script>

<style scoped>
.personalize {
  padding: 40px 20px;
  margin-top: 40px;
}
.personalize h2 {
  text-align: center;
  color: var(--cor_principal_lp);
  font-weight: 600;
  font-size: 26px;
}
.personalize .casas {
  width: 100%;
  min-height: 255px;
  background: #f3f3f3;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px;
  margin-top: 20px;
  background-size: cover;
  background-repeat: no-repeat;
}
.personalize .casas a {
  padding: 15px 30px;
  background: rgba(79, 79, 79, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  color: #fff;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .personalize {
    padding: 20px;
    min-width: 100%;
    max-width: 100%;
  }
  .personalize h2 {
    font-size: 20px;
    text-align: left;
    margin-top: 20px;
  }
}
</style>>
