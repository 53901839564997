<template>
  <div>
    <header class="container base-menu" v-if="!loading">
      <img :src="logo" alt="logo" />
      <div class="btn-topo">
        <button class="btn btn btn-share" @click="showShare">
          <i class="fas fa-share-alt mr-2"></i>COMPARTILHAR
        </button>
        <button
          class="btn btn-orange"
          @click="callBroker"
          v-if="whatsApp_corretor"
        >
          FALAR COM CORRETOR
        </button>
      </div>
    </header>
    <header class="container base-menu" v-if="loading">
      <b-skeleton animation="wave" width="10%" type="button"></b-skeleton>

      <div class="btn-topo" style="min-width: 300px">
        <b-skeleton animation="wave" width="50%" type="button"></b-skeleton>
        <b-skeleton animation="wave" width="50%" type="button"></b-skeleton>
      </div>
    </header>

    <div class="modal sharesocial" id="my_modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">Compartilhe</h5>
      </div>
      <div class="modal-body">
        <p v-if="social_links.facebook_url !==''" @click="defineShareLinks(social_links.facebook_url, 'facebook')">Facebook <i class="fab fa-facebook-f"></i
                ></p> 
        <p v-if="social_links.linkedin_url !==''" @click="defineShareLinks(social_links.linkedin_url, 'linkedin')">Linkedin  <i class="fab fa-linkedin"></i
                ></p> 

          <p v-if="social_links.twitter_url !==''"  @click="defineShareLinks(social_links.twitter_url, 'twitter')">Twitter<img src="@/assets/img/twitter.png" alt="Qsuite" style="max-height: 40px;"> </p>

        <div v-if="social_links.facebook_url == '' && social_links.linkedin_url=='' && social_links.twitter_url == ''">
            <p>Não há redes sociais para serem compartilhadas</p>
        </div> 
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" @click="showShare" data-bs-dismiss="modal">Fechar</button>

      </div>
    </div>
  </div>
</div>
  </div>
</template>

<script>
export default {
  name: "MenuLP",
  props: {
    logo: {
      type: String,
      default: "",
    },
    social_links: {
      type: Object,

    },
    whatsApp_corretor: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    whatsApp() {
      return `https://api.whatsapp.com/send?phone=${this.whatsApp_corretor}`;
    },
  },
  methods: {
    copyWhatsBroker() {
      navigator.clipboard.writeText(this.whatsApp);
      this.$bvToast.toast("WhatsApp copiado com sucesso", {
        title: "Compartilhar",
        autoHideDelay: 5000,
        variant: "success",
      });
    },
    openClose() {
      $("#my_modal").toggle()
    },
    showShare() {
      $(".sharesocial").toggle()
    },
    defineShareLinks(link, type) {
        var pageUrl = encodeURIComponent(document.URL);
        var tweet = encodeURIComponent($("meta[property='og:description']").attr("content"));

        if (type == "facebook") {
          let url = "https://www.facebook.com/sharer.php?u=" + link
          this.socialWindow(url);
        }
        if (type == "twitter") {
          let url = "https://twitter.com/intent/tweet?url=" + link + "&text=" + tweet;
            this.socialWindow(url);
        }
        if (type == "instagram") {
          let url = "https://www.linkedin.com/shareArticle?mini=true&url=" + link;
            this.socialWindow(url);
        }
        if (type == "linkedin") {
          let url = "https://www.linkedin.com/shareArticle?mini=true&url=" + link;
            this.socialWindow(url);
        }
      },
      socialWindow(url) {
        var left = (screen.width - 570) / 2;
        var top = (screen.height - 570) / 2;
        var params = "menubar=no,toolbar=no,status=no,width=570,height=570,top=" + top + ",left=" + left;

        window.open(url, "NewWindow", params);
      },

    callBroker() {
      window.open(this.whatsApp);
    },
  },
  mounted() {
    console.log(this.social_links);
  }
};
</script>

<style scoped>
.btn {
  padding: 0 20px;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
}
.btn-orange {
  background: #d96113;
}
.btn-share {
  background: transparent;
  color: #7c8798;
  border: 1px solid #7c8798;
}
/* menu */
.base-menu,
.btn-topo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.base-menu {
  padding: 40px 20px;
}
.base-menu img {
  width: 100px;
}
.btn-topo {
  gap: 20px;
}
@media screen and (max-width: 768px) {
  .base-menu {
    padding: 30px 20px;
    min-width: 100%;
    max-width: 100%;
    flex-direction: column;
    gap: 20px;
  }
  .base-menu img {
    width: 70px;
  }
  .btn {
    padding: 0 10px;
    font-size: 10px;
  }
}


/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 15% auto; /* 15% from the top and centered */
  padding: 20px;
  border: 1px solid #888;
  width: 80%; /* Could be more or less, depending on screen size */
}

/* The Close Button */
.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.fab {
  font-size: 21px
}
</style>