<template>
  <div>
    <section class="main" v-if="corretor">
      <!-- <img src="@/assets/img/big/img3.jpg" alt="bg" class="bg-corretor" /> -->
      <img :src="bg" alt="bg" class="bg-corretor" />
      <div class="corretor">
        <div class="topo">
          <!-- <img
            :src="corretor.image"
            alt="corretor-img"
            class="foto"
            v-if="corretor.image"
          /> --><img :src="foto_corretor" alt="corretor-img" class="foto" />
          <div>
            <span class="creci">CRECI {{ corretor.creci }}</span>
            <h1 class="nome" :style="'color:'+color">
              {{ corretor.first_name }} <br />
              {{ corretor.last_name }}
            </h1>
            <div class="imobiliaria" v-if="corretor.real_state !=null && corretor.real_state.length">
              <span>IMOBILIÁRIA</span>
              <a href="#" class="btn btn-share">{{
                corretor.real_state[0].fantasy_name
              }}</a>
            </div>
            <div class="social">
              <div class="redes" v-if="corretor.social_media">
                <a v-if="corretor.social_media.facebook_url!=''" :href="corretor.social_media.facebook_url" target="_blank"
                  ><i class="fab fa-facebook-f"></i
                ></a>
                <a  v-if="corretor.social_media.twitter_url!=''" :href="corretor.social_media.twitter_url" target="_blank"
                  >
                <img src="@/assets/img/twitter.png" alt="Qsuite" style="max-height: 23px;"></a>
                  <a v-if="corretor.social_media.linkedin_url !==''" :href="corretor.social_media.linkedin_url" > <i class="fab mt-1 fa-linkedin"></i></a> 

                <a  v-if="corretor.social_media.instagram_url!=''" :href="corretor.social_media.instagram_url" target="_blank"
                  ><i width="40" class="fab fa-instagram mt-1"></i
                ></a>
              </div>
              <a
                :href="'https://api.whatsapp.com?phone='+corretor_whatsapp"
                target="_blank"
                v-if="corretor_whatsapp != ''"
                ><i class="fab fa-whatsapp"></i
              ></a>
              <a
                :href="`mailto:${corretor.email}`"
                target="_blank"
                v-if="corretor.email"
                ><i class="far fa-envelope mt-1"></i
              ></a>
            </div>
          </div>
        </div>

        <div class="texto">
          <h2>{{titulo}}</h2>
          <p>
           {{texto}}
          </p>
        </div>
      </div>
    </section>

    <section class="main" v-if="loading">
      <b-skeleton-img class="bg-corretor"></b-skeleton-img>
      <div class="corretor">
        <div class="topo">
          <!-- <b-skeleton-img class="foto"></b-skeleton-img> -->
          <div>
            <span class="creci">CRECI </span>
            <h1 class="nome">
              <b-skeleton animation="wave" width="100%"></b-skeleton>
              <b-skeleton animation="wave" width="40%"></b-skeleton>
            </h1>
            <div class="imobiliaria">
              <span>IMOBILIÁRIA</span>
              <b-skeleton
                animation="wave"
                type="button"
                width="100%"
              ></b-skeleton>
            </div>
            <div class="social">
              <div class="redes">
                <b-skeleton animation="wave" type="avatar"></b-skeleton>
                <b-skeleton animation="wave" type="avatar"></b-skeleton>
                <b-skeleton animation="wave" type="avatar"></b-skeleton>
                <b-skeleton animation="wave" type="avatar"></b-skeleton>
              </div>
            </div>
          </div>
        </div>

        <div class="texto">
          <h2>{{titulo}}</h2>
          <p>
           {{texto}}
          </p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "MainCorretor",
  props: {
    bg: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "black"
    },
    titulo: {
      type: String,
      default: ""
    },
    texto: {
      type: String,
      default: ""
    },

    corretor: {
      type: Object,
      default: () => {},
    },
    foto_corretor: {
      type: String,
      default: "",
    },

    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      corretor_whatsapp: ""
    }
  },
  mounted() {
    if (this.corretor.whatsapp != null) {
    let um = this.corretor.whatsapp.split("(");
    um = um[1].split(")")
    let dois  = um[1].split(" ")[1].split("-")

     this.corretor_whatsapp = "55"+um[0]+dois[0]+dois[1]
    }
  
   
  }
};
</script>

<style scoped>
.btn {
  padding: 0 20px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 600;
}
.btn-orange {
  background: var(--cor_principal_lp);
}
.btn-share {
  background: transparent;
  color: #8b8b8b;
  border: 1px solid #8b8b8b;
}
/* main */
.main {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 20px;
  /* background: url("../../assets/img/bg-corretor.png") center center no-repeat;
  min-height: 76vh; */
}
.main .bg-corretor {
  border-bottom-right-radius: 80px;
  height: 72vh;
  width: 100%;
  object-fit: cover;
}
.corretor {
  padding: 0 30px 0 20px;
}
.corretor .topo {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 30px;
  margin-bottom: 30px;
}
.corretor .foto {
  border-radius: 50px 0px 0px 0px;
  width: 150px;
  object-fit: cover;
  height: 200px;
}
.corretor .creci,
.corretor .imobiliaria span {
  color: #8b8b8b;
  text-transform: uppercase;
  font-size: 13px;
}
.corretor .nome {
  color: #ef7d18;
  font-weight: bold;
  margin-bottom: 10px;
  max-width: 150px;
}
.corretor .imobiliaria {
  display: block;
  max-width: 150px;
  text-align: center;
}
.corretor .imobiliaria span {
  display: block;
  margin-bottom: 3px;
  font-size: 11px;
}
.corretor .imobiliaria a {
  width: 100%;
  border-color: var(--cor_principal_lp);
  text-transform: uppercase;
  font-weight: 600;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.corretor .social,
.corretor .social .redes {
  display: flex;
  align-items: center;
  gap: 20px;
}
.corretor .social {
  margin: 20px 0;
}
.corretor .social a i {
  font-size: 22px;
  color: #8b8b8b;
}
.corretor .texto h2,
.corretor .texto p {
  color: #8b8b8b;
}
.corretor .texto h2 {
  font-weight: bold;
  font-size: 25px;
}
.corretor .texto p {
  font-size: 15px;
  font-weight: 500;
  margin-top: 20px;
}
@media screen and (max-width: 768px) {
  .main {
    grid-template-columns: 1fr;
  }
  .main .bg-corretor {
    display: none;
  }
  .corretor .topo {
    grid-template-columns: 1fr 3fr;
  }
  .corretor .texto h2 {
    font-size: 20px;
  }
  .corretor .texto p {
    font-size: 14px;
  }
}
</style>