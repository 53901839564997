<template>
    <div>
  <div
    class="lp"
    :style="{
      '--cor_principal_lp': theme.cor_principal,
    }"
  >

    <!-- {{ corretor }} -->
    <!-- menu -->
    
    <Menu
      :whatsApp_corretor="whatsApp_corretor"
      :social_links="social_links"
      :loading="loading"
      :logo="theme.logo_dark"
    />

    <!-- main -->
    <MainCorretor :corretor="broker" :titulo="theme.title" :texto="theme.text" :foto_corretor="broker?.image" :color="theme.cor_principal" :loading="loading" :bg="theme.bg != '' ? theme.bg : 'https://placehold.co/600x400?text=Sem+Imagem'" :foto_topo="theme.foto_topo" />

    <!-- Empreendimentos -->
    <Empreendimentos :corretor_id="corretor_id" :loading="loading" />

    <!-- formulario -->
    <Formulario
      :loading="loading"
      :brokerEmail="broker.email"
      v-if="broker"
      :enterprises="broker.enterprises"
    />

    <!-- personalize -->
    <Personalize :personalize_img="theme.foto_personalize" :loading="loading" />
<!--     
    <h2 class="text-center mb-3" :style="'color:'+theme.cor_principal">{{theme.titulocorretor}}</h2>
    <div class="d-flex mx-auto justify-content-center pr-4 pl-4 w-100" v-if="theme.bannerdesktop != '' && theme.bannermobile != ''" >
            <img v-if="mobile==false" :src="theme.bannerdesktop" class="img-fluid m-3 bder-default" title="Banner2" style="width:70%">
 
            <img v-if="mobile==true"  :src="theme.bannermobile" class="w-100  m-3 mr-3 ml-3 bder-default" title="Banner2">
    
    </div> -->
    <!-- footer -->
    <Footer
      :loading="loading"
      :site="theme.site"
      :logo="theme.logo_dark"
      :company_address="broker ? broker.company_address : null"
    />
  </div>
  </div>
</template>

<script>
import LandingPageService from "@/services/resources/LandingPageService";
const service = LandingPageService.build();

// comp
import Menu from "@/components/LandingPage/Menu.vue";
import MainCorretor from "@/components/LandingPage/MainCorretor.vue";
import Empreendimentos from "@/components/LandingPage/Empreendimentos.vue";
import Formulario from "@/components/LandingPage/Formulario.vue";
import Personalize from "@/components/LandingPage/Personalize.vue";
import Footer from "@/components/LandingPage/Footer.vue";

export default {
  name: "LPCorretor",
  components: {
    Menu,
    MainCorretor,
    Empreendimentos,
    Formulario,
    Personalize,
    Footer,
  },
  data() {
    return {
      loading: true,
      corretor_id: null,
      broker: null,
      enterprises: null,
      social_links:"",
      whatsApp_corretor: null,
      mobile:false,
      theme: {
        logo: "",
        icone: "",
        title:"",
        text:"",
        titulo: "",
        cor_principal: "",
        bg: "",
        site: "",
        foto_topo: "",
        foto_personalize: "",
        bannerdesktop:"",
        bannermobile: "",
        titulocorretor: ""
      },
    };
  },
  methods: {
    getTheme(theme) {
      theme.forEach((element) => {
        switch (element.key) {
          case "SETTINGS_LOGO":
            this.theme.logo_dark = element.value;
            break;

          case "PORTAL_CLIENT_BACKGROUND_DESKTOP":
            this.theme.foto_topo = element.value;
            break;
          case "SETTINGS_LOGO_WHITE":
            this.theme.logo_light = element.value;
            break;
          case "SETTINGS_ICO":
            this.theme.icone = element.value;
            $("#favicon").attr("href", element.value);
            break;
          case "SETTINGS_DISPLAY":
            this.theme.titulo = element.value;
            document.title = `${this.broker.first_name} ${this.broker.last_name} | ${element.value}`;
            break;
          case "PORTAL_BROKER_COMPONENT_COLOR":
            this.theme.cor_principal = element.value;
            break;
          case "PORTAL_BROKER_BACKGROUND_DESKTOP":
            this.theme.bg = element.value;
            break;
          case "PORTAL_BROKER_BACKGROUND_MOBILE_LOGGED":
            this.theme.bannermobile = element.value;
            break;
           case "PORTAL_BROKER_BACKGROUND_DESKTOP_LOGGED":
            this.theme.bannerdesktop = element.value;
            break;
            case "BROKER_LP_TITLE":
            this.theme.title = element.value;
            break;
            case "BROKER_LP_TEXT":
            this.theme.text = element.value;
            break;
             case 'PORTAL_BROKER_SENTENCE_LOGGED':
                this.theme.titulocorretor= element.value
                break;

            case "SETTINGS_DOMAIN_MASTER":
            this.theme.site = "https://"+element.value;
            break;
        }
      });
    },
    fetchCorretor() {
      this.loading = true;
      service
        .read(`/broker/${this.corretor_id}`)
        .then((resp) => {
          this.broker = resp;
          console.log(this.broker);
          this.getTheme(resp.themes);
         
          this.tel_corretor = {
            tel: this.broker.cellphone,
            areacode: this.broker.telephone_area_code,
            iso: this.broker.telephone_area_code_iso,
          };
 this.social_links =  resp.social_media
        console.log(this.social_links);
          
          
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
 this.site=window.location.host
    if (document.body.clientWidth<801) {
     
      this.mobile = true
    }
    document.title = "---";
    this.corretor_id = this.$route.params.id.split("-");
    this.corretor_id = this.corretor_id[this.corretor_id.length - 1];
    this.fetchCorretor();
  },
};
</script>

<style scoped>
/* init */
.lp {
  background: #fff !important;
}
.btn {
  padding: 0 20px;
  border-radius: 50px;
  font-size: 10px;
  font-weight: 600;
}
.btn-orange {
  background: var(--cor_principal_lp);
}
.btn-share {
  background: transparent;
  color: #8b8b8b;
  border: 1px solid #8b8b8b;
}
</style>
