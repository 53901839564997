<template>
  <div>
    <footer class="footer" v-if="!loading">
      <div class="container">
        <img :src="logo" alt="logo" />

        <div class="info-footer">
          <p class="endereco" v-if="company_address">
            {{ company_address.street }}, {{ company_address.number }}<br />
            {{ company_address.neighborhood }}, {{ company_address.city }} -
            {{ company_address.state }} <br />
            {{ company_address.cep }}
          </p>
          <div class="links-uteis">
            <a href="#">Portal do Corretor</a>
            <a
              :href="'https://'+site+'/politica-de-privacidade'"
              target="_blank"
              >Política de Privacidade</a
            >
              <a
              :href="'https://'+site+'/termos-de-uso'"
              target="_blank"
              >Termos de uso</a
            >
            <router-link to="/novo-cadastro">Quero ser um corretor</router-link>
          </div>
        </div>
      </div>
      <div class="rodape">
        <div class="container">
          <p>
            © Todos os direitos
            reservados.
          </p>
          <img src="@/assets/img/logo-gsuite.png" alt="logo g suite" />
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: String,
      default: "",
    },
    // site: {
    //   type: String,
    //   default: "",
    // },
    company_address: {
      type: Object,
      default: () => {},
    },
  },
  mounted(){
     this.site=window.location.host
  }
};
</script>

<style scoped>
.footer {
  background: #343434;
  padding: 0;
}
.footer .container {
  padding: 40px 20px;
}
.footer img {
  width: 100px;
}
.footer .info-footer {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 50px;
}
.footer .info-footer .endereco {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  line-height: 1.6;
}
.footer .info-footer .links-uteis a {
  color: #fff;
  display: block;
  text-align: right;
  font-size: 14px;
}
.footer .info-footer .links-uteis a + a {
  margin-top: 7px;
}
.footer .info-footer .links-uteis a:hover,
.footer .info-footer .links-uteis a:last-child {
  color: var(--cor_principal_lp);
}
.footer .rodape {
  background: #ffffff;
  padding: 30px 20px;
}
.footer .rodape .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
}
.footer .rodape .container p {
  margin: 0;
}
@media screen and (max-width: 768px) {
  .footer .container,
  .footer .rodape .container {
    padding: 30px 20px;
    min-width: 100%;
    max-width: 100%;
    gap: 30px;
  }
  .footer .rodape {
    padding: 20px 0;
  }
  .footer .info-footer,
  .footer .rodape .container {
    /* display: block; */
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
  .footer .info-footer .links-uteis a {
    text-align: left;
  }
}
</style>