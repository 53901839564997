<template>
  <section class="container empreendimentos">
    <h2>EMPREENDIMENTOS</h2>
    <div v-if="!loading && enterprises.length">
      <carousel
        :per-page="width < 500 ? 1 : isMobile ? 2 : 4"
        :spacePadding="1"
      >
        <slide v-for="item in enterprises" :key="item.id" v-if="item.showcan=='sim'">
          <div class="card-emp" v-if="item.showcan=='sim'">
            <div class="imagem-nome">
              <img :src="item.image_principal || item.image" :alt="item.name" />
              <span class="nome"> {{ item.name }} </span>
            </div>

            <!-- <p class="descricao">
              {{ item.description || item.name }}
            </p> -->
          </div>
        </slide>
      </carousel>
    </div>

    <div v-if="loading">
      <carousel
        :per-page="width < 500 ? 1 : isMobile ? 2 : 4"
        :spacePadding="1"
      >
        <slide>
          <div class="card-emp">
            <div class="imagem-nome">
              <b-skeleton-img height="350px"></b-skeleton-img>
            </div>

            <p class="descricao mt-2">
              <b-skeleton animation="wave" width="50%"></b-skeleton>
            </p>
          </div>
        </slide>
        <slide>
          <div class="card-emp">
            <div class="imagem-nome">
              <b-skeleton-img height="350px"></b-skeleton-img>
            </div>

            <p class="descricao mt-2">
              <b-skeleton animation="wave" width="80%"></b-skeleton>
            </p>
          </div>
        </slide>
        <slide>
          <div class="card-emp">
            <div class="imagem-nome">
              <b-skeleton-img height="350px"></b-skeleton-img>
            </div>

            <p class="descricao mt-2">
              <b-skeleton animation="wave" width="30%"></b-skeleton>
            </p>
          </div>
        </slide>
        <slide>
          <div class="card-emp">
            <div class="imagem-nome">
              <b-skeleton-img height="350px"></b-skeleton-img>
            </div>

            <p class="descricao mt-2">
              <b-skeleton animation="wave" width="70%"></b-skeleton>
            </p>
          </div>
        </slide>
      </carousel>
    </div>
  </section>
</template>

<script>
import LandingPageService from "@/services/resources/LandingPageService";
const service = LandingPageService.build();

import { Carousel, Slide } from "vue-carousel";
export default {
  name: "Empreendimentos",
  components: {
    Carousel,
    Slide,
  },
  data() {
    return {
      enterprises: [],
      loading: true,
      corretor_id: null,
      width: null,
    };
  },
  computed: {
    isMobile() {
      return this.width <= 968;
    },
  },
  methods: {
    handleResize() {
      // padrão
      this.width = window.innerWidth;
    },
    fetchEnterprises() {
      this.loading = true;
      service
        .read(`/enterprises/${this.corretor_id}`)
        .then((resp) => {
          this.enterprises = resp;
        })
        .catch((error) => console.log(error))
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    this.corretor_id = this.$route.params.id.split("-");
    this.corretor_id = this.corretor_id[this.corretor_id.length - 1];
    this.fetchEnterprises();
  },
};
</script>

<style scoped>
.empreendimentos {
  padding: 80px 20px;
}
.empreendimentos h2 {
  color: var(--cor_principal_lp);
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}
.empreendimentos .card-emp {
  cursor: move;
  position: relative;
  margin-right: 15px;
}
.empreendimentos .card-emp + .card-emp {
  /* margin-right: 15px; */
}
.empreendimentos .card-emp img {
  object-fit: cover;
  /* width: 260px; */
  width: 100%;
  height: 400px;
  /* border-radius: 0px 30px 0px 0px; */
  /* border-radius: 10px; */
  margin-bottom: 20px;
}
.empreendimentos .card-emp .imagem-nome {
  position: relative;
}
.empreendimentos .card-emp span {
  background: rgba(52, 52, 52, 0.6);
  border-radius: 30px 0px 0px 0px;
  padding: 15px 25px;
  color: #fff;
  display: inline-block;
  position: absolute;
  bottom: 20px;
  left: 0px;
  z-index: 999;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
}
.empreendimentos .card-emp p {
  /* margin-top: 30px; */
  color: #4e4e4e;
  font-size: 11px;
  font-weight: 600;
}
@media screen and (max-width: 768px) {
  .empreendimentos {
    min-width: 100%;
    max-width: 100%;
    padding: 40px 20px;
  }
  .empreendimentos h2{
    font-size: 16px;
  }
}
</style>