<template>
  <div>
    <section class="container formulario" v-if="!loading">
      <b-form @submit.prevent="onSubmit">
        <div class="titulo-form">
          <h2>Falar com <span>o corretor</span></h2>
          <p>Preencha nosso formulário<br />abaixo ou envie-nos um e-mail.</p>
        </div>
        <div class="itens-form">         
            <b-form-input
              type="text"
              placeholder="NOME"
              required
              v-model="form.name"
            />
            <b-form-input
              type="email"
              placeholder="E-MAIL"
              required
              v-model="form.email"
            />       
        
            <div class="position-relative">
              <b-form-select
              class="position-relative"
                name="empreedimento"
                id="empreedimento"
                placeholder="QUAL EMPREEDIMENTO DESEJAR FALAR?"
                required
                @change="mandaNome()"
                v-model="form.enterpriseName"
                :options="enterprises_options"
              >
                                  
              </b-form-select>
              <b class="position-absolute" style="right: 23px; top: 18px">&#8595</b>    
            </div>
            <b-form-input
              type="tel"
              placeholder="TELEFONE"
              required
              maxlength="15"
              @keyup="mascara($event)"
              v-model="form.telefone"
            />
            <div class="footer-form">
              <b-form-checkbox
                required
                class="form-lp"
                v-model="form.privacyPolicy"
              >
                Ao enviar o formulário, aceito e concordo com os termos de uso e
                política de privacidade.
              </b-form-checkbox>
              <button class="btn btn-orange" :disabled="!form.privacyPolicy">
                Enviar
              </button>
            </div>
     
        </div>
      </b-form>
    </section>

    <section class="container formulario" v-if="loading">
      <b-skeleton-img></b-skeleton-img>
    </section>
  </div>
</template>

<script>
import LandingPageService from "@/services/resources/LandingPageService";
const service = LandingPageService.build();

export default {
  name: "Formulario",
  props: {
    brokerEmail: {
      type: String,
      default: "",
    },
    enterprises: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading_form: false,
      enterprises_options: [
        {
          value: null,
          text: "Selecione um empreendimento",
        },
      ],
      form: {
        name: "",
        email: "",
        company_id: "",
        privacyPolicy: false,
        leads_origin_id: 2,
        telefone: "",
        enterpriseName: null,
        enterpriseName2: ""
      },
    };
  },
  methods: {
    onSubmit() {
      this.loading_form = true;

       
      const data = {
        ...this.form,
        brokerEmail: this.brokerEmail,
        url: "/send-mail",
      };
      service
        .create(data)
        .then((resp) => {
          if (resp.name != "Error") {
            this.$bvToast.toast("Dados enviados com sucesso", {
              title: "Falar com o corretor",
              autoHideDelay: 5000,
              variant: "success",
            });
          } else {
            this.$bvToast.toast("Ocorreu um erro ao enviar os dados", {
              title: "Falar com o corretor",
              autoHideDelay: 5000,
              variant: "danger",
            });
          }
        })
        .catch(() => {
          this.$bvToast.toast("Ocorreu um erro ao enviar os dados", {
            title: "Falar com o corretor",
            autoHideDelay: 5000,
            variant: "danger",
          });
        })
        .finally(() => {
          this.loading_form = false;
        });
    },
    mandaNome(){
        
      this.enterprises.filter((ele) => {
        if(ele.id == this.form.enterpriseName){
          this.form.enterpriseName2 = ele.name
        }
      })
    },
    mascara(va) {
       
        let telefone = va.srcElement.value.replace(/\D+/g, "");

      
            telefone = telefone
            let tamanho = telefone.length;
            if (tamanho >= 12) {
                return false;
            }

            if (tamanho > 10) {
                telefone = telefone.replace(/^(\d\d)(\d{5})(\d{4}).*/, "($1) $2-$3");
            } else if (tamanho > 5) {
                telefone = telefone.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, "($1) $2-$3");
            } else if (tamanho > 2) {
                telefone = telefone.replace(/^(\d\d)(\d{0,5})/, "($1) $2");
            } else {
                telefone = telefone.replace(/^(\d*)/, "($1");
            }

            va.srcElement.value = telefone;
        

        
    }
  },
  mounted() {
    this.form["company_id"]=this.enterprises[0].company_id
    this.enterprises.forEach((item) => {
      if(item.showcan=='sim'){
        this.enterprises_options.push({
          value: item.id,
          text: item.name,
        });
      }
    });
  },
};
</script>

<style scoped>
.btn {
  padding: 0 20px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 600;
}
.btn-orange {
  background: var(--cor_principal_lp);
}
/* FORM */
.formulario {
  padding: 0 20px;
}
.formulario .titulo-form,
.formulario .itens-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}
.formulario .itens-form {
  margin-top: 20px;
}

.formulario h2 {
  font-size: 32px;
  color: #232323;
  font-weight: 600;
  text-align: center;
}
.formulario h2 span {
  color: var(--cor_principal_lp);
}
.formulario p {
  font-size: 18px;
  color: #656666;
  text-align: center;
}
.formulario input,
.formulario select {
  background: #f3f3f5 !important;
  height: 60px;
  padding: 20px;
  margin-bottom: 15px;
  color: #232323;
}
.formulario input:hover,
.formulario input:focus,
.formulario select:hover,
.formulario select:focus {
  border-color: #d96113 !important;
  box-shadow: none !important;
}
.formulario .footer-form {
  display: flex;
  align-items: center;
  gap: 20px;
}
.formulario .footer-form button {
  padding: 5px 40px;
  /* height: 50px; */
  border-radius: 10px;
  font-size: 14px;
  height: 60px;
}
@media screen and (max-width: 768px) {
  .formulario {
    min-width: 100%;
    max-width: 100%;
  }
  .formulario .titulo-form,
  .formulario .itens-form {
    grid-template-columns: 1fr;
  }
  .formulario h2,
  .formulario p {
    text-align: left;
  }
  .formulario h2 {
    font-size: 26px;
  }
  .formulario p {
    font-size: 16px;
  }
  .formulario .footer-form {
    /* display: block; */
    flex-direction: column;
  }
  .formulario .footer-form button {
    width: 100%;
    padding: 0 10px;
  }
}
</style>